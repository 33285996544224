import './Contact.css';

export default function Contact() {

    return (
        <div className="Contact">
            <h2>CONTATTA HairZiz</h2>
            <div className="follow-me">
                <a href="https://instagram.com/HairZiz" target="_blank" rel="noreferrer"><i class="fa-brands fa-instagram"></i></a>

            </div>
            <div className="follow-me">

                <a href="https://www.facebook.com/HairZiz/" target="_blank" rel="noreferrer"><i class="fa-brands fa-facebook"></i></a>

            </div>
            <div className="follow-me">


                <a href="tel : 055 289141"><i class="fa-solid fa-phone"></i></a>
                
            </div>
            <div className="follow-me">

                <a href="mailto:hairzizshop@gmail.com" target="_blank" rel="noreferrer"><i class="fa-regular fa-envelope"></i></a>
            </div>
            <h6>Se sei un nuovo cliente, desideri assistere o desideri lasciare una testimonianza, compila il modulo corrispondente qui sotto:</h6>

        </div>
    );
}

/*
            <div className="forms">
                <a href="https://form.jotform.com/223598389579178" target="_blank" rel="noreferrer">Nuovo Cliente</a>
                <a href="https://form.jotform.com/223598139985172" target="_blank" rel="noreferrer">Modulo dell'Assistente</a>
                <a href="https://form.jotform.com/223597889261170" target="_blank" rel="noreferrer">KP Health Coaching - Testimonial</a>
                <a href="https://form.jotform.com/223598631679169" target="_blank" rel="noreferrer">HairZiz - Recenzioni</a>
            </div>
*/