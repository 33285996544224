import './Booking.css';

export default function Booking() {
    return (
        <div className="Booking">
            <h2>Dove Siamo</h2>
            <div className="cities">
                <div className="chi">
                    
                    
                    <div className="dates">
                        
                        
                    </div>
                    <div className="salon">
                        
                        
                    </div>
                    
                </div>
                <div className="sf">
                    <h1>Firenze</h1>
                    <a className="booking-link" href="https://kpxaeh.as.me/sanfrancisco" target="_blank" rel="noreferrer">Booking Link</a>
                    <div className="dates">
                    </div>
                    <div className="salon">
                        <h6>HairZiz</h6>
                        <li><a href="https://www.google.com/maps/place/Via+Ghibellina,+109r,+50122+Firenze+FI/@43.7704103,11.2574052,17z/data=!3m1!4b1!4m6!3m5!1s0x132a5406ec2b1335:0x7e0965a7ec9de2db!8m2!3d43.7704103!4d11.2599855!16s%2Fg%2F11fkklkk7z?entry=ttu" target="_blank" rel="noreferrer">Via Ghibellina, 109<br/>Firenze</a></li>
                    </div>
                <hr />
                </div>
                <div className="ny">


                </div>
            </div>
            <p></p>
        </div>
    );
}
/*

                <div className="chi">
                    <h1>Firenze1</h1>
                    <a className="booking-link" href="https://kpxaeh.as.me/chicago" target="_blank" rel="noreferrer">Booking Link</a>
                    <div className="dates">
                        <li>August 12 + 13</li>
                        <li>November 11 + 12</li>
                    </div>
                    <div className="salon">
                        <h6><a href="https://www.solo-salon.com/" target="_blank" rel="noreferrer">Solo Salon</a></h6>
                        <li><a href="https://www.google.com/maps/place/SOLO+SALON+WASHINGTON/@41.8831539,-87.6557881,15z/data=!4m6!3m5!1s0x880e2cd8ffb2a28d:0xd97919872e4abb89!8m2!3d41.8831539!4d-87.6557881!16s%2Fg%2F11f11d9p_7" target="_blank" rel="noreferrer">1134 W Washington Blvd<br/>Chicago, IL 60607</a></li>
                    </div>
                    <hr />
                </div>
                
 <div className="ny">
                    <h1>Firenze2</h1>
                    <a className="booking-link" href="https://kpxaeh.as.me/newyork" target="_blank" rel="noreferrer">Booking Link</a>
                    <div className="dates">
                        <li>July 16</li>
                        <li>October 15</li>
                    </div>
                    <div className="salon">
                        <h6><a href="https://www.mariabonitany.com/" target="_blank" rel="noreferrer">Maria Bonita Salon</a></h6>
                        <li><a href="https://www.google.com/maps/place/Maria+Bonita+Salon+%26+Spa/@40.721263,-73.995793,15z/data=!4m6!3m5!1s0x89c25985e52603cf:0x7d9c3d0804b3cc3e!8m2!3d40.721263!4d-73.995793!16s%2Fg%2F1th0z897" target="_blank" rel="noreferrer">199 Mott St<br/>New York, NY 10012</a></li>
                    </div>
                    <hr />
                    <br />
                </div>

                */