import './Nav.css';

export default function Nav({ currentPage, setCurrentPage }) {
  return (
    <div className="Nav">
        <div className="nav1">
            <ul>
                <li onClick={() => setCurrentPage('booking')} className="hover">Dove Siamo</li>
                <li className="hover" onClick={() => setCurrentPage('services')}>Servizi</li>
                <li className="hover" onClick={() => setCurrentPage('prices')}>Prezzi</li>
            </ul>
        </div>
        <div className="title">
            <h1 className="hover" onClick={() => setCurrentPage('home')}>HairZiz</h1>
        </div>
        <div className="nav2">
            <ul>
                <li className="hover" onClick={() => setCurrentPage('about')}>Chi siamo</li>
                <li className="hover" onClick={() => setCurrentPage('testimonials')}>Recensioni</li>
                <li className="hover" onClick={() => setCurrentPage('contact')}>Contatti</li>
            </ul>
        </div>
    </div>
  );
}