import './Testimonials.css';

export default function Testimonials() {
    return (
        <div className="Testimonials">
            <h2>RECENSIONI</h2>
            <div id="testimonials">
                <div className="testimonials-1">
                    <h3>"Ne vale la pena"</h3>
                    <p>
                    Bravissimi ed economici, il che non guasta, ottimo taglio, professionali, accoglienti e…simpatici. Da consigliare vivamente !!
                        <br/><br/>- <span className="name">Liviana L.</span>
                    </p>
                </div>
                <div className="testimonials-1">
                    <h3>"Professionali"</h3>
                    <p>
                    Ambiente essenziale parrucchiere bravo nel taglio e lavoro tecnico.rapporto qualità prezzo molto buono. Da provare sicuramente.
                        <br/><br/>- <span className="name">Stefania R.</span>
                    </p>
                </div>
                <div className="testimonials-1">
                    <h3>"Bravi"</h3>
                    <p>
                    Molto bravi e veloci. La cosa più importante per me che si può fare la piega senza l'appuntamento. Grazie ragazzi.
                        <br/><br/>- <span className="name">Maddy P.</span>
                    </p>
                </div>
                <div className="testimonials-1">
                    <h3>"The Best"</h3>
                    <p>
                    The gentlemen were very friendly and professional. They did an amazing job with my hair. I was very satisfied and I recommend the place for everyone in town.
                        <br/><br/>- <span className="name">Alli F.</span>
                    </p>
                </div>
            </div>
        </div>
    );
}