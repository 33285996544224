import './About.css';

export default function About() {
    return (
        <div className="about-container">
            <img className="kristen" src="assets/kp-bg.png" alt=""/>
            <div className="About">
                <h3>A proposito di </h3><h2>HairZiz</h2>
                <h6>Ci prendiamo cura dei vostri capelli</h6>
                <p>Negozio di parrucchiere uomo e donna in via Ghibellina, nel cuore di Firenze a fianco al teatro Verdi. Salone di bellezza per taglio, piega, acconciatura e trattamento dei capelli con utilizzo di prodotti biologici. Barbiere professionista.
                <br/><br/>Parrucchiere disponibile per taglio ai più piccoli. Gestito da professionisti con 30 anni di esperienza. Ti aspettiamo nel nostro salone per coccolarti a 360°.
                <br/> Naturalmente “Ci prendiamo cura dei vostri capelli.“
                <br/><br/>Crediamo che sia possibile prenderci cura dei tuoi capelli, e intanto avere rispetto e attenzione verso la natura che ci circonda. Questa è la nostra filosofia. In questo modo ci prendiamo cura del nostro corpo e al contempo del nostro pianeta.
                <br/><br/>La nostra filosofia attenta all’ambiente e alla natura ci ha portato a scegliere anche prodotti biologici. Si tratta di prodotti ottenuti da prodotti vegetali, non testati sugli animali e ottenuti utilizzando ingredienti certificati e coltivati con tecniche di agricoltura biologica e biodinamica.</p>
            </div>
        </div>
    );
}