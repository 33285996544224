import "./Prices.css";

export default function Prices({ currentPage, setCurrentPage }) {
  return (
    <div className="Prices">
      <h2>Prezzi</h2>
      <div>
        <table>
          <tr>
            <th>I Nostri Servizi</th>
            <th>Prezzi</th>
          </tr>
          <tr>
            <td>
              Piega/ Blow dry
              <br />
              (Blow dry)
            </td>
            <td>15 - 25 €</td>
          </tr>
          <tr>
            <td>
              Taglio
              <br />
              (Hair cut)
            </td>
            <td>15 €</td>
          </tr>
          <tr>
            <td>
              Colore <br /> (Color)
            </td>
            <td>20 – 35 €</td>
          </tr>
          <tr>
            <td>
              Schiariture
              <br /> (Hightlights)
            </td>
            <td> 30 – 50 €</td>
          </tr>
          <tr>
            <td>
              Taglio uomo <br />
              (Mens cut)
            </td>
            <td>15 €</td>
          </tr>
          <tr>
            <td>
              Barba <br /> (Beard)
            </td>
            <td>8 €</td>
          </tr>
        </table>
        <p>Per scoprire tutti i prezzi vi aspettiamo all’ interno</p>
      </div>
    </div>
  );
}
