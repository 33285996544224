import "./Services.css";

export default function Services({ currentPage, setCurrentPage }) {
  return (
    <div className="Services">
      <h2>Servizi</h2>
      <div >
      <table>
          <tr>
            <th>Donna</th>
            <th>Uomo</th>
          </tr>
          <tr>
            <td>Taglio</td>
            <td>Taglio</td>
          </tr>
          <tr>
            <td>Taglio e piega + shampoo + conditioner</td>
            <td>Taglio e barba + piega + shampoo</td>
          </tr>
          <tr>
            <td>Shampoo</td>
            <td>Shampoo</td>
          </tr>
          <tr>
            <td>Colore</td>
            <td>Colore</td>
          </tr>
          <tr>
            <td>Meches + Contrasti + Highlight</td>
            <td>Stiratura relax</td>
          </tr>
          <tr>
            <td>Multicolore</td>
   
          </tr>
          <tr>
            <td>Colpi di sole e cartine</td>

          </tr>
          <tr>
            <td>Shatush</td>

          </tr>
          <tr>
            <td>Lisciatura</td>

          </tr>
          <tr>
            <td>Rigenerante con olio di Argan</td>

          </tr>
        </table>
        <p>Per scoprire tutti i servizi vi aspettiamo all’ interno</p>
      </div>
    </div>
  );
}
